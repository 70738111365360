import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { sessionStore } from "./store";
import { IAccountContext } from "./types";
import { Loader } from "@mantine/core";
import { useRouter } from "next/router";
import { persistUTMQueryParamsToString } from "@common/utils/router";

export const AccountContext = createContext<IAccountContext>({});

export function AccountProvider({ children }: PropsWithChildren): ReactElement {
  const router = useRouter();

  // account
  const {
    isLoading: loadingAccount,
    refetch: getAccount,
    data: account,
  } = sessionStore.useGetAccount({
    enabled: true,
    refetchInterval: 1000 * 60 * 60, // reload every hour
  });

  // first account loading
  const [firstLoading, setFirstLoading] = useState(true);
  useEffect(() => {
    async function getAccountDetailsAsync() {
      await getAccount();
      setFirstLoading(false);
    }

    getAccountDetailsAsync();
  }, [getAccount]);

  // redirects
  useEffect(() => {
    if (!loadingAccount) {
      const { pathname, query, asPath } = router;

      const isLanding = pathname === "/";
      const isAccount = pathname.includes("/account");
      const isSnapshots = pathname.includes("/snapshots/");
      if (isLanding || isAccount || isSnapshots) {
        const isLoggedIn = !!account;

        // redirect to /account
        if (isLanding && isLoggedIn) {
          const { redirectTo } = query;
          router.push(
            persistUTMQueryParamsToString(
              router,
              decodeURIComponent(redirectTo ? String(redirectTo) : "/account"),
            ),
          );
        }
        // redirect to /
        else if (!isLanding && !isLoggedIn) {
          const redirectTo = encodeURIComponent(asPath);
          router.push(
            persistUTMQueryParamsToString(
              router,
              `./?redirectTo=${redirectTo}`,
            ),
          );
        }
      }
    }
  }, [loadingAccount, router, account]);

  if (loadingAccount && firstLoading) {
    return <Loader m="xl" />;
  }

  return (
    <AccountContext.Provider value={{ account }}>
      {children}
    </AccountContext.Provider>
  );
}
