export const MetabotMessageSent = () => {
  return (
    <svg width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <path
          d="M1 13a3 3 0 0 1 3-3h28a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V13Z"
          fill="#DDECF9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.5 13A3.5 3.5 0 0 1 4 9.5h28a3.5 3.5 0 0 1 3.5 3.5v18a3.5 3.5 0 0 1-3.5 3.5H4A3.5 3.5 0 0 1 .5 31V13ZM4 10.5A2.5 2.5 0 0 0 1.5 13v18A2.5 2.5 0 0 0 4 33.5h28a2.5 2.5 0 0 0 2.5-2.5V13a2.5 2.5 0 0 0-2.5-2.5H4Z"
          fill="#509EE3"
        />
        <path
          d="M5 15a1 1 0 0 1 1-1h24a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V15Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 15A1.5 1.5 0 0 1 6 13.5h24a1.5 1.5 0 0 1 1.5 1.5v12a1.5 1.5 0 0 1-1.5 1.5H6A1.5 1.5 0 0 1 4.5 27V15Zm1.5-.5a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h24a.5.5 0 0 0 .5-.5V15a.5.5 0 0 0-.5-.5H6ZM28 30.75a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5ZM28 32a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM31 30.75a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5ZM31 32a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
          fill="#509EE3"
        />
        <circle cx="12.75" cy="19.25" r="1.25" fill="#509EE3" />
        <circle cx="23.25" cy="19.25" r="1.25" fill="#509EE3" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.363 22.164c.134.123.347.211.637.211.29 0 .503-.088.637-.211.125-.116.238-.316.238-.664h1.25c0 .646-.223 1.197-.64 1.582-.41.378-.947.543-1.485.543-.538 0-1.076-.165-1.485-.543-.417-.386-.64-.936-.64-1.582h1.25c0 .348.113.548.238.664Z"
          fill="#509EE3"
        />
        <path
          d="M29.822 5.604a.5.5 0 0 1 .616.616l-4.427 15.813a.5.5 0 0 1-.89.154l-4.629-6.52a.5.5 0 0 0-.118-.118l-6.52-4.629a.5.5 0 0 1 .155-.889l15.813-4.427Z"
          fill="#509EE3"
          fillOpacity=".25"
        />
        <path
          d="m32.678 1.364-4.95 17.678-5.285-7.444L15 6.314l17.678-4.95Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.031 1.01a.5.5 0 0 1 .128.489l-4.95 17.677a.5.5 0 0 1-.889.155l-5.235-7.374-7.374-5.236a.5.5 0 0 1 .154-.889L32.543.882a.5.5 0 0 1 .488.128Zm-1.075 1.075L16.145 6.513l6.589 4.678a.499.499 0 0 1 .118.118l4.678 6.589 4.427-15.813Z"
          fill="#509EE3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m28.081 6.709-5.42 5.421-.708-.707 5.421-5.421.707.707Z"
          fill="#509EE3"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const MetabotMessageReceived = () => {
  return (
    <svg width="80" height="56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 19a5 5 0 0 1 5-5h46a5 5 0 0 1 5 5v30a5 5 0 0 1-5 5H17a5 5 0 0 1-5-5V19Z"
        fill="#DDECF9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.375 19A5.625 5.625 0 0 1 17 13.375h46A5.625 5.625 0 0 1 68.625 19v30A5.625 5.625 0 0 1 63 54.625H17A5.625 5.625 0 0 1 11.375 49V19ZM17 14.625A4.375 4.375 0 0 0 12.625 19v30A4.375 4.375 0 0 0 17 53.375h46A4.375 4.375 0 0 0 67.375 49V19A4.375 4.375 0 0 0 63 14.625H17Z"
        fill="#509EE3"
      />
      <path
        d="M18 22a2 2 0 0 1 2-2h40a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2H20a2 2 0 0 1-2-2V22Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.375 22A2.625 2.625 0 0 1 20 19.375h40A2.625 2.625 0 0 1 62.625 22v20A2.625 2.625 0 0 1 60 44.625H20A2.625 2.625 0 0 1 17.375 42V22ZM20 20.625c-.76 0-1.375.616-1.375 1.375v20c0 .76.616 1.375 1.375 1.375h40c.76 0 1.375-.616 1.375-1.375V22c0-.76-.616-1.375-1.375-1.375H20ZM54 49.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM54 51a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM60 49.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM60 51a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        fill="#509EE3"
      />
      <circle cx="31.75" cy="29.25" r="1.75" fill="#509EE3" />
      <circle cx="48.25" cy="29.25" r="1.75" fill="#509EE3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.833 35.225c.27.249.67.4 1.167.4s.898-.151 1.167-.4c.261-.241.458-.628.458-1.225h1.25c0 .894-.307 1.633-.86 2.143-.545.504-1.27.732-2.015.732-.745 0-1.47-.228-2.015-.732-.553-.51-.86-1.249-.86-2.143h1.25c0 .597.197.984.458 1.225Z"
        fill="#509EE3"
      />
      <circle cx="60" cy="22" r="12" fill="#509EE3" fillOpacity=".25" />
      <path
        d="M76 18c0 6.627-5.373 12-12 12s-12-5.373-12-12S57.373 6 64 6s12 5.373 12 12Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64 6.625c-6.282 0-11.375 5.093-11.375 11.375S57.718 29.375 64 29.375 75.375 24.282 75.375 18 70.282 6.625 64 6.625ZM51.375 18c0-6.973 5.652-12.625 12.625-12.625S76.625 11.027 76.625 18 70.973 30.625 64 30.625 51.375 24.973 51.375 18Z"
        fill="#509EE3"
      />
      <path
        d="M66.231 20.883V22h-4.477v-1.117h1.584v-4.367a7.806 7.806 0 0 1 .022-.594l-1.045.87a.517.517 0 0 1-.412.11.558.558 0 0 1-.166-.067.495.495 0 0 1-.11-.099l-.478-.643 2.453-2.08h1.248v6.87h1.381Z"
        fill="#509EE3"
      />
      <g opacity=".5" fillRule="evenodd" clipRule="evenodd" fill="#509EE3">
        <path d="M74.375 32.5v-2h1.25v2h-1.25ZM74 34.125h-2v-1.25h2v1.25ZM74.375 36.5v-2h1.25v2h-1.25ZM78 34.125h-2v-1.25h2v1.25Z" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.375 2.5v-2h1.25v2h-1.25ZM44 4.125h-2v-1.25h2v1.25ZM44.375 6.5v-2h1.25v2h-1.25ZM48 4.125h-2v-1.25h2v1.25Z"
        fill="#509EE3"
      />
    </svg>
  );
};
