import { createContext, PropsWithChildren, ReactElement } from "react";
import { IConfig } from "./types";
import { configStore } from "./store";

export const ConfigContext = createContext<IConfig>({} as IConfig);

export function ConfigProvider({ children }: PropsWithChildren): ReactElement {
  const { isLoading, error, data: config } = configStore.useGetConfig();
  if (isLoading || error || !config) {
    return <></>;
  }

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
}
