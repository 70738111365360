import { NextRouter } from "next/router";
import { UrlObject } from "url";
import { ParsedUrlQuery } from "querystring";

export function getHashParams(
  url: string,
): Record<string, undefined | string | number> {
  const urlSplit = url.split("#");
  if (urlSplit.length > 1) {
    const hash = {};
    urlSplit[1]
      .split("&")
      .map((paramStr) => paramStr.split("="))
      .forEach(([key, valueStr]) => {
        hash[key] = isNaN(parseFloat(valueStr))
          ? valueStr
          : parseFloat(valueStr);
      });

    return hash;
  }

  return {};
}

export function filterParams(
  query: ParsedUrlQuery,
  queryParams: Record<string, any>,
  startsWith: string,
): Record<string, any> {
  Object.keys(query).forEach((key) => {
    if (key.startsWith(startsWith)) {
      queryParams[key] = query[key];
    }
  });

  return queryParams;
}

// UTM
// Will persist utm_* query params (utm_source, utm_medium, utm_campaign, utm_term, utm_content, etc.)
// For urls such as: ?utm_source=facebook&utm_medium=paid_social&utm_campaign=summer_sale&utm_term=social_media&utm_content=video_ad
export function getUTMParams(
  query: ParsedUrlQuery,
  queryParams = {},
): Record<string, any> {
  return filterParams(query, queryParams, "utm_");
}

// Google Click Identifier
// Will persist gclid_* query params
export function getGCLIDParams(
  query: ParsedUrlQuery,
  queryParams = {},
): Record<string, any> {
  return filterParams(query, queryParams, "gclid_");
}

// UrlObject is now LEGACY
// https://nodejs.org/api/url.html#legacy-urlobject

export function persistUTMQueryParamsToUrlObject(
  router: NextRouter,
  pathname = "",
  queryParams = {},
  hash = "",
): UrlObject {
  // 1. hash params from pathname
  if (pathname.indexOf("#") > -1) {
    const pathnameSplit = pathname.split("#");
    pathname = pathnameSplit[0];
    hash = pathnameSplit[1];
  }

  // 2. query params from pathname
  if (pathname.indexOf("?") > -1) {
    // get query params
    const queryParamsStr = pathname.split("?")[1];
    const queryParamsArr = queryParamsStr.split("&");
    queryParamsArr.forEach((queryParamStr) => {
      const [key, param] = queryParamStr.split("=");
      queryParams[key] = param;
    });

    // keep url only
    pathname = pathname.split("?")[0];
  }

  // persist query params from router
  const { query } = router;
  queryParams = getUTMParams(query, queryParams);
  queryParams = getGCLIDParams(query, queryParams);

  if (Object.keys(queryParams).length === 0) {
    return { pathname, hash };
  }

  return {
    pathname,
    hash,
    query: queryParams,
  };
}

export function persistUTMQueryParamsToString(
  router: NextRouter,
  pathname = "",
  queryParams = {},
): string {
  // query params from pathname
  if (pathname.indexOf("?") > -1) {
    // get query params
    const currentQueryParamsStr = pathname.split("?")[1];
    const currentQueryParamsArr = currentQueryParamsStr.split("&");
    const currentQueryParamsObj = {};
    currentQueryParamsArr.forEach((queryParamStr) => {
      const [key, param] = queryParamStr.split("=");
      currentQueryParamsObj[key] = param;
    });

    // overwrite current query params
    queryParams = {
      ...currentQueryParamsObj,
      ...queryParams,
    };

    // keep url only
    pathname = pathname.split("?")[0];
  }

  // persist query params from router
  const { query } = router;
  queryParams = getUTMParams(query, queryParams);
  queryParams = getGCLIDParams(query, queryParams);

  if (Object.keys(queryParams).length === 0) {
    return pathname;
  }

  const formattedQueryParams = Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&");
  return `${pathname}?${formattedQueryParams}`;
}
