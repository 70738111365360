import { useQuery } from "@common/utils/use-query";
import { ConfigStore } from "./types";

export const configStore: ConfigStore = {
  useGetConfig() {
    return useQuery(
      { url: "/config" },
      {
        refetchInterval: 1000 * 60 * 60 * 24, // reload every 24 hours
      },
    );
  },
};
