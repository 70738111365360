import { Box, Group, Stack, Text } from "@mantine/core";
import {
  MetabotMessageReceived,
  MetabotMessageSent,
} from "@common/components/icons/Metabot";
import ButtonAsText from "@common/components/thirdParty/mantine/ButtonAsText";
import { useMagicLinkStyles } from "./MagicLinks.styled";
import type { ReactElement } from "react";
import type {
  IMagicLinkConfirmationProps,
  IMagicLinkFormControlProps,
} from "./types";

export const MagicLinkFormControlBox = ({
  infoText,
  linkText,
  onClick,
}: IMagicLinkFormControlProps): ReactElement => {
  const { classes } = useMagicLinkStyles();

  return (
    <Box p="md" className={classes.container}>
      <Group spacing="md" align="center" noWrap position="apart">
        <Box className={classes.noShrink}>
          <MetabotMessageSent />
        </Box>
        <Text color="gray.4" fz="xs" className={classes.grow}>
          {infoText}{" "}
          <ButtonAsText
            onClick={onClick}
            c="inherit"
            fz="xs"
            td="underline"
            className={classes.clickable}
          >
            {linkText}
          </ButtonAsText>
        </Text>
      </Group>
    </Box>
  );
};

export const MagicLinkConfirmationContent = ({
  email,
  onClick,
}: IMagicLinkConfirmationProps): ReactElement => {
  const { classes } = useMagicLinkStyles();

  return (
    <Box className={classes.container} mt="md">
      <Stack align="center" c="gray.7" spacing={0} px="md" py="xl">
        <Box mb="md">
          <MetabotMessageReceived />
        </Box>
        <Text align="center" color="inherit" fz="sm">
          <b>Check your inbox!</b> If you have a Metabase account, a login link
          is on its way to <b>{email}</b>
        </Text>
      </Stack>
      <ButtonAsText
        className={classes.loginBox}
        onClick={onClick}
        c="brand.4"
        fw={700}
        py="md"
        w="100%"
      >
        Back to login
      </ButtonAsText>
    </Box>
  );
};
