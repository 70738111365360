import { MagicLinkStore } from "./types";
import { useMutation, useQueryClient } from "@common/utils/use-query";

export const magicLinkStore: MagicLinkStore = {
  useInitiateMagicLinkLogin() {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: "/magic_link",
        method: "POST",
      },
      {
        onError(error) {
          if (error.status === 429) {
            error.message = "Please try to login again in a couple of minutes.";
          }
        },
        onSuccess() {
          return queryClient.invalidateQueries("/session");
        },
      },
    );
  },

  useValidateLoginToken(token: string) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: `/magic_link/${token}`,
        method: "POST",
      },
      {
        onError(error) {
          if (error.status === 429) {
            error.message = "Please try to login again in a couple of minutes.";
          }
        },
        onSuccess() {
          return queryClient.invalidateQueries("/session");
        },
      },
    );
  },
};
