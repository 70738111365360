import { Anchor, Box, Flex, Text, useMantineTheme } from "@mantine/core";
import { ReactElement } from "react";
import { HEADER_NAV_MESSAGE_HEIGHT } from "./constants";
import { persistUTMQueryParamsToString } from "@common/utils/router";
import Link from "next/link";
import IconArrowRight from "@common/components/icons/IconArrowRight";
import { useRouter } from "next/router";
import IconInfo from "@common/components/icons/IconInfo";
import { IHeaderNavBannerProps } from "./types";

export function HeaderNavBanner({
  text,
  href,
  hrefText,
}: IHeaderNavBannerProps): ReactElement {
  const theme = useMantineTheme();

  const router = useRouter();

  return (
    <Box data-testid="HeaderNav-message" bg="yellow.3" ta="center" w="100%">
      <Flex
        align="center"
        direction="row"
        gap="sm"
        justify="flex-start"
        h={HEADER_NAV_MESSAGE_HEIGHT}
        px="xl"
        py="xs"
        w="100%"
      >
        <IconInfo size={14} />

        <Text c="gray.6" fz={{ base: "xs", xs: "sm" }}>
          {text}
        </Text>

        {href && hrefText && (
          <Anchor
            component={Link}
            href={persistUTMQueryParamsToString(router, href)}
            c="gray.6"
          >
            <Flex align="center" direction="row" justify="center">
              <Text
                span
                fw={700}
                fz={{ base: "xs", xs: "sm" }}
                mr={theme.spacing.xs / 2}
              >
                {hrefText}
              </Text>
              <IconArrowRight color={theme.colors.gray[6]} />
            </Flex>
          </Anchor>
        )}
      </Flex>
    </Box>
  );
}
