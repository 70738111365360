import { padLeft } from "../string";
import { TWO_WEEKS } from "./constants";

// TO BE REVIEWED
export function convertToDateTimeString(date: string | Date): string {
  if (typeof date === "object") {
    return `${date.getFullYear()}-${padLeft(date.getMonth() + 1)}-${padLeft(
      date.getDate(),
    )}T00:00:00Z`;
  }

  return date.includes("T") ? date : `${date}T00:00:00Z`;
}

export function normalize(date: Date | string | number): Date {
  if (typeof date === "string" || typeof date === "number") {
    date = new Date(date);
  }

  // invalidate date
  if (date.toString() === "Invalid Date") {
    throw new Error("Invalid Date");
  }

  return new Date(date.getTime());
}

export function setTime(date: Date | string | number, time: Date): string {
  const dateCopy = normalize(date);
  dateCopy.setHours(time.getHours());
  dateCopy.setMinutes(time.getMinutes());

  return dateCopy.toISOString().replace(".000", "");
}

export function prettyTime(time: number): string {
  if (time === 0 || time === 24) {
    return "midnight";
  }

  return `${time % 12}${time / 12 > 1 ? "pm" : "am"}`;
}

export function isSameDate(
  date1: Date | string | number,
  date2: Date | string | number = new Date(),
): boolean {
  const formattedDate1 = normalize(date1);
  const formattedDate2 = normalize(date2);

  return (
    formattedDate1.getFullYear() === formattedDate2.getFullYear() &&
    formattedDate1.getMonth() === formattedDate2.getMonth() &&
    formattedDate1.getDate() === formattedDate2.getDate()
  );
}

export function addMonths(
  date: Date | string | number,
  offset: number,
): string {
  const formattedDate = normalize(date);
  const newDate = new Date(
    formattedDate.setMonth(formattedDate.getMonth() + offset),
  );
  return `${newDate.toISOString().split("T")[0]}T12:00:00Z`;
}

export function getISODateWithOffset(
  date?: Date | string | number,
  offset = TWO_WEEKS,
): string {
  const time = date ? normalize(date).getTime() : Date.now();
  const newDate = new Date(time + offset);
  return `${newDate.toISOString().split("T")[0]}T12:00:00Z`;
}
