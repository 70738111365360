// regions

// staging
export const REGION_US_WEST_2_VALUE = "us-west-2";
export const REGION_US_WEST_2_NAME = "US West (Oregon)";

// production
export const REGION_US_EAST_1_VALUE = "us-east-1";
export const REGION_US_EAST_1_NAME = "US East (N. Virginia)";

export const REGION_SA_1_VALUE = "sa-east-1";
export const REGION_SA_1_NAME = "South America (São Paulo)";

// feature flags

export const FEATURE_FLAG_ALLOW_NO_CC_ID = "allow-no-cc";
export const FEATURE_FLAG_MAGIC_LOGIN_LINKS = "magic-login-links";

export const FEATURE_FLAGS_IDS = [
  "allow-throttle-mb-emails",
  "connections",
  "magic-login-links",
  "self-service-invoicing",
  "kill-hosting-single-tenant",
  "kill-clickhouse-dwh",
  "kill-eu-central-1-region",
  "new-prods",
  "kill-stripe-user-count-sync",
  "custom-image-tracking-enabled",
  "kill-hosting-multi-tenant",
  "scheduled-maintenance-enabled",
  "kill-stripe-subscription-sync",
  "allow-enterprise-contracts-jobs",
  "kill-non-stripe-subscription-sync",
  "multi-product-subscriptions",
  "allow-create-snapshot-errors",
  "support-users",
  "elt-sync",
  "dwh",
  FEATURE_FLAG_ALLOW_NO_CC_ID,
  FEATURE_FLAG_MAGIC_LOGIN_LINKS,
] as const;
