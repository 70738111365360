import { createStyles } from "@mantine/core";

export const useMagicLinkStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.colors.brand[1],
    borderRadius: theme.radius.md,
  },

  noShrink: {
    flexShrink: 0,
  },

  grow: {
    flexGrow: 1,
  },

  clickable: {
    cursor: "pointer",

    "&:hover": {
      color: theme.colors.brand[4],
    },
  },

  loginBox: {
    borderTop: `1px solid ${theme.colors.gray[3]}`,
    borderBottomLeftRadius: theme.radius.md,
    borderBottomRightRadius: theme.radius.md,
    textAlign: "center",

    "&:hover": {
      backgroundColor: theme.fn.lighten(theme.colors.brand[4], 0.75),
    },
  },
}));
