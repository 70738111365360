import { SessionStore } from "./types";
import { useMutation, useQuery, useQueryClient } from "@common/utils/use-query";

export const sessionStore: SessionStore = {
  useCreateAccount(params) {
    const queryClient = useQueryClient();

    const queryParams = new URLSearchParams(params);
    const queryParamsStr = queryParams.size ? `?${queryParams.toString()}` : "";
    const url = `/account${queryParamsStr}`;

    return useMutation(
      { url, method: "POST" },
      {
        onSuccess() {
          return queryClient.refetchQueries("/account");
        },
      },
    );
  },

  useLogin() {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: "/session",
        method: "POST",
      },
      {
        onError(error) {
          if (error.status === 400) {
            error.message =
              "Sorry, it looks like this email is invalid. Please double-check for typos and try again.";
          } else if (error.status === 401) {
            error.message =
              "Sorry, your login failed. Please double-check for typos and try again.";
          } else if (error.status === 429) {
            error.message = "Please try to login again in a couple of minutes.";
          } else if (error.status === 500) {
            error.message =
              "Something went wrong. Please try to login again in a couple of minutes";
          } else {
            error.message =
              "An error occured. Please try to login again in a couple of minutes";
            error.status = error.status || 500;
          }
        },
        onSuccess() {
          return queryClient.refetchQueries("/account");
        },
      },
    );
  },

  useGetAccount({ enabled, refetchInterval } = { enabled: true }) {
    return useQuery({ url: "/account" }, { enabled, refetchInterval });
  },

  useUpdateAccount({ withLogout } = { withLogout: false }) {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: "/account",
        method: "PUT",
      },
      {
        onSuccess() {
          if (withLogout) {
            queryClient.clear();
          }

          return queryClient.refetchQueries("/account");
        },
      },
    );
  },

  useChangePaymentCollectionMethod() {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: "/account/payment_collection_method",
        method: "PUT",
      },
      {
        onSuccess() {
          return queryClient.refetchQueries("/account");
        },
      },
    );
  },

  useLogout() {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: "/session",
        method: "DELETE",
      },
      {
        async onSuccess() {
          queryClient.clear();
        },
      },
    );
  },
};
