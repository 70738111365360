import SnowplowAnon from "@common/components/thirdParty/snowplowanon";
import { ReactElement, useCallback, useEffect, useState } from "react";
import Hotjar from "../hotjar";
import Snowplow from "@common/components/thirdParty/snowplow";
import VWO from "../vwo";
import { GoogleAnalytics } from "@next/third-parties/google";
import GDPRCookieNotice from "../passatgt";
import { useGDPRStore } from "@stores/gdpr";
import { useRouter } from "next/router";
import { useGetAccount } from "@stores/session/hooks";

export default function Tracking(): ReactElement {
  // GDPR
  const { marketing, analytics } = useGDPRStore((state) => ({
    marketing: state.marketing,
    analytics: state.analytics,
    // performance: state.performance,
  }));

  // session
  const account = useGetAccount();

  // page url
  const [previousPageUrl, setPreviousPageUrl] = useState<string>();
  const onRouteChangeStart = useCallback(() => {
    setPreviousPageUrl(window.location.href);
  }, []);
  const router = useRouter();
  useEffect(() => {
    router.events.on("routeChangeStart", onRouteChangeStart);
    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart);
    };
  }, [router.events, onRouteChangeStart]);

  return (
    <>
      <SnowplowAnon />

      {marketing && (
        <>
          <Hotjar />
          <Snowplow
            initCallsArgs={[
              "'enableActivityTracking', 10, 10",
              "'enableLinkClickTracking'",
            ]}
            referrerUrl={previousPageUrl || document.referrer}
            account={account}
          />
          <VWO />
        </>
      )}

      {analytics && <GoogleAnalytics gaId={process.env.GA} />}

      <GDPRCookieNotice />
    </>
  );
}
